import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { styled } from 'styles';
import { Button, Input } from 'components';
import notify from 'notify';
import { tryGetFirstError } from 'utils/requests';
import OrderContext from './OrderContext';
import { updateOrderStatus } from './api';

const RejectionForm = () => {
  const { token, setResolveOrder } = useContext(OrderContext);
  const { register, errors, handleSubmit } = useForm();

  const submit = async form => {
    try {
      if (!token) throw Error('Token is missing');
      await updateOrderStatus({ token, action: 'reject', note: form.note });
      setResolveOrder('reject');
    } catch (err) {
      notify(tryGetFirstError(err));
    }
  };

  return (
    <StyledRejectionForm className="rejection-form" onSubmit={handleSubmit(submit)}>
      <h4 className="title">Rejection Reason</h4>
      <Input ref={register} name="note" error={errors.note?.message} as="textarea" />

      <Button className="rejection-form__btn">Submit</Button>
    </StyledRejectionForm>
  );
};

export default React.memo(RejectionForm);

const StyledRejectionForm = styled.form`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: 0 0 0 16px;
  .input {
  }
  textarea.input-component {
    height: 80px;
    resize: vertical;
  }
  .rejection-form__btn {
    margin-top: 27px;
    height: 40px;
  }
`;
