import React, { useContext, useMemo } from 'react';

import { styled } from 'styles';
import { HeaderOnlyLayout } from 'components';
import OrderContext, { OrderContextWrapper } from './OrderContext';
import NotFound from './NotFound';
import RequestInfo from './RequestInfo';
import UploadForm from './UploadForm';
import RejectionForm from './RejectionForm';
import RequestResolved from './RequestResolved';

const acceptMessage = (
  <div className="accept-message">
    <h4 className="title">Thank you for accepting the request</h4>
    <p>
      You will receive the confirmation with deadline and upload links in the next email. Once
      you’ll be ready - you can upload documents and invoice by the link you’ll receive.
    </p>
    <strong>You can close this window now.</strong>
  </div>
);

const OrderPage = () => {
  const { action, token, orderData, resolveOrder } = useContext(OrderContext);

  const content = useMemo(() => {
    if (resolveOrder === 'complete') {
      return (<RequestResolved type={resolveOrder}>
        Request is successfully completed. <br />
        Thank you!
      </RequestResolved>);
    }
    if (resolveOrder === 'reject') {
      return (<RequestResolved type={resolveOrder}>
        Request is successfully rejected.
      </RequestResolved>);
    }
    if (!token || !action) {
      return <NotFound />;
    }
    switch (action) {
      case 'accept':
        return (
          <>
            <RequestInfo orderData={orderData} />
            {acceptMessage}
          </>
        );
      case 'reject':
        return (
          <>
            <RequestInfo orderData={orderData} />
            <RejectionForm />
          </>
        );
      case 'upload':
        return (
          <>
            <RequestInfo orderData={orderData} />
            <UploadForm />
          </>
        );
      default:
        return <NotFound />;
    }
  }, [action, resolveOrder, orderData, token]);

  return (
    <HeaderOnlyLayout>
      <StyledOrderPage className="vendor-page">{content}</StyledOrderPage>
    </HeaderOnlyLayout>
  );
};

export default props => (
  <OrderContextWrapper>
    <OrderPage {...props} />
  </OrderContextWrapper>
);

const StyledOrderPage = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 32px auto;
  width: 100%;
  max-width: 1200px;
  font-size: 14px;
  line-height: 18px;
  min-height: 100vh;
  h4.title {
    margin-bottom: 15px;
    font-size: 16px;
    font-weight: 600;
    line-height: 32px;
  }
  .accept-message {
    flex: 1;
    margin: 0 0 0 16px;
  }
`;
